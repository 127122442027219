import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  Stack,
  Typography,
  Checkbox,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Chip,
  CircularProgress,
} from "../../mui";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import AdminService from "../../services/AdminService";
import { snackbarActions } from "../../Store/snackbar";
import { useDispatch } from "react-redux";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

const rolesHeaders = ["Name", "Is Assigned"];
const subServicesHeaders = ["Name", "Is Assigned", "Permission Level"];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.turquoise.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const UserRoleMangement = ({ group, username, setExpanded }) => {
  const [userGroup, setUserGroup] = useState(group);
  const [subServicesList, setSubServicesList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [subServiceTd, setSubServiceTd] = useState([]);
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const handleGroupChange = (e) => {
    setTableData(userData.filter((rl) => rl?.group === e.target.value));
    setUserGroup(e.target.value);
  };

  const handleRoleChange = (e, pos) => {
    setTableData(
      tableData.map((el, index) => {
        if (pos === index) return { ...el, is_assigned: e.target.checked };
        else return el;
      })
    );
  };

  const handleSave = () => {
    const req_body = {
      user_email: username,
      group: userGroup,
      roles: tableData.reduce((acc, curr) => {
        if (curr.is_assigned) acc.push(curr.role_name);
        return acc;
      }, []),
    };
    setProcessing(true);
    AdminService.updateUserRoleAccess(req_body)
      .then((resp) => {
        setExpanded(false);
        setProcessing(false);
        queryClient.invalidateQueries({ queryKey: ["get_all_users_access"] });
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );     
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        setProcessing(false);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof error?.response?.data?.detail === "string"
                ? error.response.data.detail
                : "Something went wrong.",
          })
        );
      });
  };

  useEffect(() => {
    let temp = {};
    tableData.forEach((el) => {
      if (el.is_assigned) {
        el.role_subservice.forEach((ss) => {
          temp[ss.service_name] =
            temp[ss.service_name] === "EXEC" ? "EXEC" : ss.access_level;
        });
      }
    });
    const tempKeys = Object.keys(temp);
    setSubServiceTd(
      subServicesList.reduce((acc, curr) => {
        const is_assigned = tempKeys.includes(curr);
        acc.push({
          subservice_name: curr,
          is_assigned,
          permission_level: is_assigned ? temp[curr] : "VIEW",
        });
        return acc;
      }, [])
    );
  }, [tableData]);

  useEffect(() => {
    setLoading(true);
    AdminService.getUserRoleAccess(username, userGroup)
      .then((resp) => {
        setUserData(resp.data.roles);
        setTableData(resp.data.roles.filter((el) => el.group === userGroup));
        setSubServicesList(resp.data.all_subservices);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof error?.response?.data?.detail === "string"
                ? error.response.data.detail
                : "Something went wrong.",
          })
        );
      });
  }, []);

  return (
    <Box
      component={Paper}
      variant="outlined"
      p=".5rem 1rem"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Stack direction="row" mt="5px">
        <Typography width="12%" minWidth="100px" margin="auto">
          User Type:
        </Typography>
        <FormControl
          sx={{
            minWidth: 200,
          }}
          size="small"
        >
          <InputLabel id="select-group"></InputLabel>
          <Select            
            labelId="select-group"
            id="select-role-gp"
            variant="filled"
            value={userGroup}
            label=""
            onChange={handleGroupChange}
            sx={{
              "& .MuiInputBase-input": {
                paddingY: "6px",
              },
            }}
          >
            <MenuItem value="internal">Internal</MenuItem>
            <MenuItem value="external">External</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Divider width="100%" sx={{ margin: "10px 0" }} />
      <Stack direction="row" gap="5rem" alignItems="start">
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Chip
            label={<Typography sx={{ fontSize: 15 }}>Assign Roles</Typography>}
            color="secondary"
            variant="elevated"
            sx={{ padding: "1rem 2rem" }}
          />

          <TableContainer
            variant="outlined"
            sx={{ margin: "4px 0", height: "350px" }}
            component={Paper}
          >
            <Table
              size="small"
              sx={{ minWidth: 400, width: "40%" }}
              aria-label="customized table"
              stickyHeader
            >
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: "#ebfde7",
                  }}
                >
                  {rolesHeaders.map((row, index) => (
                    <StyledTableCell
                      align="left"
                      key={index}
                      sx={{
                        fontWeight: "bold",
                        minWidth: "80px",
                      }}
                    >
                      {row}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow
                    sx={{
                      bgcolor: row.is_assigned
                        ? "green.light"
                        : "turquoise.light",
                    }}
                    key={index}
                  >
                    <TableCell component="th" scope="row">
                      {row.role_name}
                    </TableCell>
                    <TableCell padding="checkbox" sx={{ minWidth: "150px" }}>
                      <Checkbox
                        color="primary"
                        checked={row.is_assigned}
                        inputProps={{
                          "aria-labelledby": "checkbox",
                        }}
                        onChange={(e) => handleRoleChange(e, index)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {!tableData?.length && (
              <Stack
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                {loading ? <CircularProgress size="5rem" /> : "No data"}
              </Stack>
            )}
          </TableContainer>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Chip
            label={
              <Typography sx={{ fontSize: 15 }}>
                Assigned Sub-Services
              </Typography>
            }
            color="secondary"
            variant="elevated"
            sx={{ padding: "1rem 1.5rem" }}
          />

          <TableContainer
            variant="outlined"
            sx={{ margin: "4px 0", height: "350px" }}
            component={Paper}
          >
            <Table
              size="small"
              sx={{ minWidth: 400, width: "60%" }}
              aria-label="customized table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {subServicesHeaders.map((row, index) => (
                    <StyledTableCell
                      align="left"
                      key={index}
                      sx={{ fontWeight: "bold", minWidth: "80px" }}
                    >
                      {row}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {subServiceTd.map((row, index) => (
                  <TableRow
                    sx={{
                      bgcolor: row.is_assigned
                        ? "green.light"
                        : "turquoise.light",
                    }}
                    key={index}
                  >
                    <TableCell component="th" scope="row">
                      {row.subservice_name}
                    </TableCell>

                    <TableCell align="center" component="td" scope="row">
                      {row.is_assigned ? (
                        <CheckRoundedIcon fontSize="small" />
                      ) : (
                        <ClearRoundedIcon fontSize="small" />
                      )}
                    </TableCell>
                    <TableCell>{row.permission_level}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {!subServiceTd?.length && (
              <Stack
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                {loading ? <CircularProgress size="5rem" /> : "No data"}
              </Stack>
            )}
          </TableContainer>
        </Stack>
      </Stack>
      <Button
        variant="contained"
        disabled={!tableData.length || processing}
        onClick={handleSave}
        sx={{ width: "250px", mt: "10px" }}
        color="success"
      >
        {processing ? "Processing" : "Save Role Access"}
      </Button>
    </Box>
  );
};

export default UserRoleMangement;
